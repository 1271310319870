import { gql, useMutation, useQuery } from "@apollo/client"
import * as Yup from 'yup'

class Progetto {
    nome = ''
    tag = ''
    dataAvvio = ''
    dataFine = ''
    durataProgetto = 0
    risorseUmaneDm = ''
    usciteSpeseDm = 0.0
    entrateSpeseDm = 0.0
    guadagnoDm = 0.0
    dataCreazioneProgetto = ''

    static importFromObject(obj) {
        const progetto = new Progetto()
        for (let attr in progetto)
            progetto[attr] = obj[attr]
        return progetto
    }
}

const CREATE_PROGETTO = gql`
    mutation CreateProgetto($input: createProgettoInput!) {
        createProgetto(input: $input) {
            progetto {
                id
            }
        }
    }
`;

const UPDATE_PROGETTO = gql`
    mutation UpdateProgetto($input: updateProgettoInput!) {
        updateProgetto(input: $input) {
            clientMutationId
        }
    }
`;

const DELETE_PROGETTO = gql`
    mutation DeleteProgetto($input: deleteProgettoInput!) {
        deleteProgetto(input: $input) {
            clientMutationId
        }
    }
`;

const GET_PROGETTOS = gql`
    query GetProgettos {
        progettos {
            edges {
                node {
                    id
                    nome
                }
            }
        }
    }
`;

const GET_PROGETTO = gql`
    query GetProgetto($id: ID!) {
        progetto(id: $id) {
            nome
            tag
            dataAvvio
            dataFine
            durataProgetto
            risorseUmaneDm
            usciteSpeseDm
            entrateSpeseDm
            guadagnoDm
            dataCreazioneProgetto
        }
    }
`;

const schemaProgetto = Yup.object().shape({
    nome: Yup.string(),
    tag: Yup.string(),
    dataAvvio: Yup.string(),
    dataFine: Yup.string(),
    durataProgetto: Yup.number(),
    risorseUmaneDm: Yup.string(),
    usciteSpeseDm: Yup.number(),
    entrateSpeseDm: Yup.number(),
    guadagnoDm: Yup.number(),
    dataCreazioneProgetto: Yup.string()
})

const useCreateProgetto = () =>
    useMutation(CREATE_PROGETTO)

const useUpdateProgetto = () =>
    useMutation(UPDATE_PROGETTO)

const useDeleteProgetto = (id = null) =>
    useMutation(DELETE_PROGETTO, {variables: {input: {id: id}}})

const useProgettos = () =>
    useQuery(GET_PROGETTOS, {pollInterval: 500})

const useProgetto = (id) =>
    useQuery(GET_PROGETTO, {variables: {id: id}})

export {
    Progetto,
    useCreateProgetto,
    useUpdateProgetto,
    useDeleteProgetto,
    useProgettos,
    useProgetto,
    schemaProgetto
}