import React from "react"
import { useClientes, useDeleteCliente } from "../services/cliente"
import { Link, navigate } from "gatsby"
import { EDIT_MODE, VIEW_MODE } from "./form"
import { useDeleteProgetto, useProgettos } from "../services/progetto"
import { gql, useQuery } from "@apollo/client"
import { useDeleteLink, useLinks } from "../services/link"

// TABELLE COMPONENTS

const RowCliente = ({cliente}) => {
    const [cancella] = useDeleteCliente(cliente.id)
    return (
        <tr>
            <td className="cell-radio">
                <div className="w-100">
                    <input
                        type="radio"
                        className="d-block mx-auto"
                    />
                </div>
            </td>
            <td className="cell-radio">
                <div className="w-100">
                    <span className="material-icons md-24 text-info">
                        person
                    </span>
                </div>
            </td>
            <td className="cell">{cliente.ragioneSociale}</td>
            <td className="text-center cell cell-15">
                <div className="d-md-flex justify-content-md-center w-100">
                    <Link
                        className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                        role="button"
                        to="/progetti"
                        state={{idCliente: cliente.id}}
                    >
                        <span className="material-icons md-18 mx-1">
                            launch
                        </span>
                        <span className="d-none d-lg-inline">
                            Apri (Visualizza Progetti)
                        </span>
                    </Link>
                </div>
            </td>
            <td className="text-center cell cell-15">
                <div className="w-100">
                    <button
                        className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                        type="button"
                        onClick={() => navigate('/scheda-cliente',{state: {id: cliente.id, mode: EDIT_MODE}})}
                    >
                        <span className="material-icons md-18 mx-1">
                            edit
                        </span>
                        <span className="d-none d-lg-inline">
                            Modifica
                        </span>
                    </button>
                </div>
            </td>
            <td className="text-center cell cell-15">
                <div className="w-100">
                    <button
                        className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                        type="button"
                        onClick={() => cancella().then(() => navigate('/archivio'))}
                    >
                        <span className="material-icons md-18 mx-1">
                            delete
                        </span>
                        <span className="d-none d-lg-inline">
                            Cancella
                        </span>
                    </button>
                </div>
            </td>
        </tr>
    )
}

const RowProgetto = ({progetto}) => {
    const [cancella] = useDeleteProgetto(progetto.id)
    return (
        <tr>
            <td className="cell-radio">
                <div className="w-100">
                    <input
                        type="radio"
                        className="d-block mx-auto"
                    />
                </div>
            </td>
            <td className="cell-radio">
                <div className="w-100">
                    <span className="material-icons md-24 text-info">
                        folder
                    </span>
                </div>
            </td>
            <td className="cell">{progetto.nome}</td>
            <td className="text-center cell cell-15">
                <div className="d-md-flex justify-content-md-center w-100">
                    <button
                        className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                        type="button"
                        onClick={() => navigate('/documenti', {state: {idProgetto: progetto.id}})}
                    >
                        <span className="material-icons md-18 mx-1">
                            launch
                        </span>
                        <span className="d-none d-lg-inline">
                            Apri (Visualizza Documenti)
                        </span>
                    </button>
                </div>
            </td>
            <td className="text-center cell cell-15">
                <div className="w-100">
                    <button
                        className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                        type="button"
                        onClick={() => navigate('/schede-progetto', {state: {id: progetto.id, mode: EDIT_MODE}})}
                    >
                        <span className="material-icons md-18 mx-1">
                            edit
                        </span>
                        <span className="d-none d-lg-inline">
                            Modifica
                        </span>
                    </button>
                </div>
            </td>
            <td className="text-center cell cell-15">
                <div className="w-100">
                    <button
                        className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                        type="button"
                        onClick={() => cancella().then(() => navigate('/archivio'))}
                    >
                        <span className="material-icons md-18 mx-1">
                            delete
                        </span>
                        <span className="d-none d-lg-inline">
                            Cancella
                        </span>
                    </button>
                </div>
            </td>
        </tr>
    )
}

const TabellaArchivio = () => {
    const GET_ARCHIVIO = gql`
        query GetArchivio {
            clientes {
                edges {
                    node {
                        id
                        ragioneSociale
                    }
                }
            }
            progettos {
                edges {
                    node {
                        id
                        nome
                    }
                }
            }
        }
    `;

    const { data, loading, error } = useQuery(GET_ARCHIVIO)

    if (loading) return 'Loading...'
    if (error) return 'Error'+error.message
    
    const ListProgetto = data.progettos.edges.map(progetto => <RowProgetto key={progetto.id} progetto={progetto.node}/>)
    const ListCliente = data.clientes.edges.map(cliente => <RowCliente key={cliente.id} cliente={cliente.node}/>)

    return (
        <div className="shadow w-100 bg-light rounded view" id="view-table">
            <div className="table-responsive bg-dark w-100 m-0 rounded">
                <table className="table table-striped table-hover table-dark table-bordered">
                    <thead>
                        <tr>
                            <th className="cell-radio">
                                <input type="radio" />
                            </th>
                            <th className="text-light" colSpan="2">
                                Cartella
                            </th>
                            <th className="text-center text-light" colSpan="3">
                                Azioni
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {ListCliente}
                        {ListProgetto}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TabellaClienti = () => {

    const { loading, error, data } = useClientes();
    const [ cancella ] = useDeleteCliente()

    return (
        <div className="shadow w-100 rounded view" id="view-table">
            <div className="table-responsive bg-dark w-100 m-0 rounded">
                <table className="table table-striped table-hover table-dark table-bordered">
                    <thead>
                        <tr>
                            <th className="cell-radio">
                                <input type="radio" />
                            </th>
                            <th className="text-light" colSpan="2">
                                Cartella
                            </th>
                            <th className="text-light text-center">STATUS</th>
                            <th className="text-center text-light" colSpan="4">
                                Azioni
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { !(loading || error) && data.clientes.edges.map(cliente => (
                            <tr key={cliente.node.id}>
                                <td className="cell-radio">
                                    <div className="w-100">
                                        <input
                                            type="radio"
                                            className="d-block mx-auto"
                                        />
                                    </div>
                                </td>
                                <td className="cell-radio">
                                    <div className="d-md-flex align-items-md-center w-100">
                                        <span className="material-icons md-24 text-info mx-1">
                                            folder
                                        </span>
                                        <span className="text-white mx-1 text-bold"></span>
                                    </div>
                                </td>
                                <td className="cell">{cliente.node.ragioneSociale}</td>
                                <td className="cell text-success text-center">
                                    <strong>{cliente.node.status}</strong>
                                </td>
                                <td className="text-center cell cell-15">
                                    <div className="d-md-flex justify-content-md-center w-100">
                                        <Link
                                            className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                            role="button"
                                            to="/scheda-cliente"
                                            state={{id: cliente.node.id, mode: VIEW_MODE}}

                                        >
                                            <span className="material-icons md-18 mx-1">
                                                info
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Scheda (Visualizza Cliente)
                                            </span>
                                        </Link>
                                    </div>
                                </td>
                                <td className="text-center cell cell-15">
                                    <div className="d-md-flex justify-content-md-center w-100">
                                        <Link
                                            className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                            role="button"
                                            href="/progetti"
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                launch
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Visualizza Progetti
                                            </span>
                                        </Link>
                                    </div>
                                </td>
                                <td className="text-center cell cell-15">
                                    <div className="w-100">
                                        <button
                                            className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                            type="button"
                                            onClick={() => navigate("/scheda-cliente",{state: {id: cliente.node.id, mode: EDIT_MODE}})}
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                edit
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Modifica
                                            </span>
                                        </button>
                                    </div>
                                </td>
                                <td className="text-center cell cell-15">
                                    <div className="w-100">
                                        <button
                                            className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                            type="button"
                                            onClick={() => cancella({variables: {input: { id: cliente.node.id }}})}
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                delete
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Cancella
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TabellaProgetti = ({idCliente = null}) => {

    const { loading, error, data } = useProgettos()
    const [ cancella ] = useDeleteProgetto()

    return (
        <div id="view-table" className="shadow w-100 rounded view">
            <div className="table-responsive bg-dark w-100 m-0 rounded">
                <table className="table table-striped table-hover table-dark table-bordered">
                    <thead>
                        <tr>
                            <th className="cell-radio">
                                <input type="radio" />
                            </th>
                            <th className="text-light cell-name">Progetti</th>
                            <th className="text-light text-center cell-status">
                                STATUS
                            </th>
                            <th className="text-center text-light" colSpan="4">
                                Azioni
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    { !(loading || error) && data.progettos.edges.map(progetto => (
                        <tr key={progetto.node.id}>
                            <td className="cell-radio">
                                <div className="w-100">
                                    <input
                                        className="d-block mx-auto"
                                        type="radio"
                                    />
                                </div>
                            </td>
                            <td className="cell-radio cell-name">
                                <div className="d-flex align-items-center">
                                    <span className="material-icons md-24 text-info mx-2">
                                        folder
                                    </span>
                                    <div className="d-flex align-items-center">
                                        <p className="m-0">{progetto.node.nome}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="cell text-success text-center cell-status">
                                <strong>{''}</strong>
                            </td>
                            <td className="text-center cell cell-15">
                                <div className="d-md-flex justify-content-md-center w-100">
                                    <Link
                                        className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                        role="button"
                                        to="/scheda-progetto"
                                        state={{id: progetto.node.id, mode: VIEW_MODE}}
                                    >
                                        <span className="material-icons md-18 mx-1">
                                            info
                                        </span>
                                        <span className="d-none d-lg-inline">
                                            Scheda (Visualizza Progetto)
                                        </span>
                                    </Link>
                                </div>
                            </td>
                            <td className="text-center cell cell-15">
                                <div className="d-md-flex justify-content-md-center w-100">
                                    <Link
                                        className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                        role="button"
                                        to="/documenti"
                                        state={{idProgetto: progetto.node.id}}
                                    >
                                        <span className="material-icons md-18 mx-1">
                                            launch
                                        </span>
                                        <span className="d-none d-lg-inline">
                                            Apri (Visualizza Documenti)
                                        </span>
                                    </Link>
                                </div>
                            </td>
                            <td className="text-center cell cell-15">
                                <div className="w-100">
                                    <button
                                        className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                        type="button"
                                        onClick={() => navigate('/scheda-progetto', {state: {id: progetto.node.id, mode: EDIT_MODE}})}
                                    >
                                        <span className="material-icons md-18 mx-1">
                                            edit
                                        </span>
                                        <span className="d-none d-lg-inline">
                                            Modifica
                                        </span>
                                    </button>
                                </div>
                            </td>
                            <td className="text-center cell cell-15">
                                <div className="w-100">
                                    <button
                                        className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                        type="button"
                                        onClick={() => cancella({variables: {input: { id: progetto.node.id }}})}
                                    >
                                        <span className="material-icons md-18 mx-1">
                                            delete
                                        </span>
                                        <span className="d-none d-lg-inline">
                                            Cancella
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TabellaDocumenti = () => (
    <div id="view-table" className="shadow w-100 rounded view">
        <div className="rounded">
            <ul className="nav nav-pills bg-dark tab-radius" role="tablist">
                <li className="nav-item tab-radius" role="presentation">
                    <a
                        className="nav-link d-flex d-xl-flex align-items-center align-items-xl-center tab-radius"
                        role="tab"
                        data-bs-toggle="pill"
                        href="#tab-1"
                    >
                        <span className="material-icons md-24 mx-1">
                            lightbulb
                        </span>
                        <span className="d-none d-md-inline">Ideazione</span>
                    </a>
                </li>
                <li className="nav-item btn-tab" role="presentation">
                    <a
                        className="nav-link d-flex d-xl-flex align-items-center align-items-xl-center tab-radius"
                        role="tab"
                        data-bs-toggle="pill"
                        href="#tab-2"
                    >
                        <span className="material-icons md-24 mx-1">
                            engineering
                        </span>
                        <span className="d-none d-md-inline">
                            Prototipazione
                        </span>
                    </a>
                </li>
                <li className="nav-item btn-tab" role="presentation">
                    <a
                        className="nav-link d-flex d-xl-flex align-items-center align-items-xl-center tab-radius"
                        role="tab"
                        data-bs-toggle="pill"
                        href="#tab-3"
                    >
                        <span className="material-icons md-24 mx-1">work</span>
                        <span className="d-none d-md-inline">
                            Distribuzione
                        </span>
                    </a>
                </li>
                <li className="nav-item ml-auto btn-tab" role="presentation">
                    <a
                        className="nav-link active d-flex d-xl-flex align-items-center align-items-xl-center tab-radius"
                        role="tab"
                        data-bs-toggle="pill"
                        href="#tab-4"
                    >
                        <span className="material-icons md-24 mx-1">
                            view_week
                        </span>
                        <span className="d-none d-md-inline">Template</span>
                    </a>
                </li>
            </ul>
            <div className="tab-content">
                <div id="tab-1" className="tab-pane" role="tabpanel">
                    <div className="shadow w-100 rounded">
                        <div className="table-responsive bg-dark w-100 m-0">
                            <table className="table table-striped table-hover table-dark table-bordered">
                                <thead>
                                    <tr>
                                        <th className="cell-radio">
                                            <input type="radio" />
                                        </th>
                                        <th className="text-light" colSpan="2">
                                            Files
                                        </th>
                                        <th className="text-light">Formato</th>
                                        <th
                                            className="text-center text-light"
                                            colSpan="4"
                                        >
                                            AZIONI
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                folder
                                            </span>
                                        </td>
                                        <td className="cell">Cartella 1</td>
                                        <td className="cell">Folder</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="documenti.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                photo
                                            </span>
                                        </td>
                                        <td className="cell">Image bg</td>
                                        <td className="cell">Png</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                article
                                            </span>
                                        </td>
                                        <td className="cell">StyleTile</td>
                                        <td className="cell">Pdf</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="tab-2" className="tab-pane" role="tabpanel">
                    <div className="shadow w-100 rounded">
                        <div className="table-responsive bg-dark w-100 m-0">
                            <table className="table table-striped table-hover table-dark table-bordered">
                                <thead>
                                    <tr>
                                        <th className="cell-radio">
                                            <input type="radio" />
                                        </th>
                                        <th className="text-light" colSpan="2">
                                            Files
                                        </th>
                                        <th className="text-light">Formato</th>
                                        <th
                                            className="text-center text-light"
                                            colSpan="4"
                                        >
                                            AZIONI
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                folder
                                            </span>
                                        </td>
                                        <td className="cell">Cartella 1</td>
                                        <td className="cell">Folder</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="documenti.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                photo
                                            </span>
                                        </td>
                                        <td className="cell">Image bg</td>
                                        <td className="cell">Png</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                article
                                            </span>
                                        </td>
                                        <td className="cell">StyleTile</td>
                                        <td className="cell">Pdf</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="tab-3" className="tab-pane" role="tabpanel">
                    <div className="shadow w-100 rounded">
                        <div className="table-responsive bg-dark w-100 m-0">
                            <table className="table table-striped table-hover table-dark table-bordered">
                                <thead>
                                    <tr>
                                        <th className="cell-radio">
                                            <input type="radio" />
                                        </th>
                                        <th className="text-light" colSpan="2">
                                            Files
                                        </th>
                                        <th className="text-light">Formato</th>
                                        <th
                                            className="text-center text-light"
                                            colSpan="4"
                                        >
                                            AZIONI
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                folder
                                            </span>
                                        </td>
                                        <td className="cell">Cartella 1</td>
                                        <td className="cell">Folder</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="documenti.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                photo
                                            </span>
                                        </td>
                                        <td className="cell">Image bg</td>
                                        <td className="cell">Png</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                article
                                            </span>
                                        </td>
                                        <td className="cell">StyleTile</td>
                                        <td className="cell">Pdf</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        info
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Scheda
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="w-100">
                                                <button
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1 d-block mx-auto"
                                                    type="button"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="tab-4" className="tab-pane active" role="tabpanel">
                    <div className="shadow w-100 rounded">
                        <div className="table-responsive bg-dark w-100 m-0">
                            <table className="table table-striped table-hover table-dark table-bordered">
                                <thead>
                                    <tr>
                                        <th className="cell-radio">
                                            <input type="radio" />
                                        </th>
                                        <th className="text-light" colSpan="2">
                                            Documento
                                        </th>
                                        <th className="text-light">
                                            Tipologia
                                        </th>
                                        <th className="text-light">Formato</th>
                                        <th className="text-light">Fase 1</th>
                                        <th className="text-light">Fase 2</th>
                                        <th className="text-light">Fase 3</th>
                                        <th
                                            className="text-center text-light"
                                            colSpan="1"
                                        >
                                            AZIONI
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="cell-radio">
                                            <div className="w-100">
                                                <input
                                                    className="d-block mx-auto"
                                                    type="radio"
                                                />
                                            </div>
                                        </td>
                                        <td className="cell-radio">
                                            <span className="material-icons md-24 text-info mx-1">
                                                folder
                                            </span>
                                        </td>
                                        <td className="cell">Cartella 1</td>
                                        <td className="cell">Cartella 1</td>
                                        <td className="cell">Folder</td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100 text-success">
                                                <span className="material-icons mx-1">
                                                    check
                                                </span>
                                                <p className="my-0">Presente</p>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100 text-success">
                                                <span className="material-icons mx-1">
                                                    check
                                                </span>
                                                <p className="my-0">Presente</p>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100 text-warning">
                                                <span className="material-icons mx-1">
                                                    warning
                                                </span>
                                                <p className="my-0">Assente</p>
                                            </div>
                                        </td>
                                        <td className="text-center cell cell-15">
                                            <div className="d-md-flex justify-content-md-center w-100">
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        history
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Versioni
                                                    </span>
                                                </a>
                                                <a
                                                    className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        launch
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Apri
                                                    </span>
                                                </a>
                                                <a
                                                    className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        edit
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Modifica
                                                    </span>
                                                </a>
                                                <a
                                                    className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                                    role="button"
                                                    href="archivio.html"
                                                >
                                                    <span className="material-icons md-18 mx-1">
                                                        delete
                                                    </span>
                                                    <span className="d-none d-lg-inline">
                                                        Cancella
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const TabellaLink = () => {
    const { loading, error, data } = useLinks();
    const [ cancella ] = useDeleteLink();
    
    return (
        <div id="view-table" className="shadow w-100 rounded view">
            <div className="rounded">
                <div className="table-responsive bg-dark w-100 m-0">
                    <table className="table table-striped table-hover table-dark table-bordered">
                        <thead>
                            <tr>
                                <th className="cell-radio">
                                    <input type="radio" />
                                </th>
                                <th className="text-light" colSpan="4">
                                    Links
                                </th>
                                <th className="text-center text-light" colSpan="4">
                                    AZIONI
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        { !(loading || error) && data.links.edges.map(link => (
                            <tr key={link.node.id}>
                                <td className="cell-radio">
                                    <div className="w-100">
                                        <input className="d-block mx-auto" type="radio"/>
                                    </div>
                                </td>
                                <td className="cell" colSpan="4">
                                    <a href="">
                                    {link.node.nome}
                                    </a>
                                </td>
                                <td className="text-center cell" colSpan="4">
                                    <div className="d-md-flex justify-content-md-center w-100">
                                        
                                        <a 
                                            className="btn btn-primary d-flex justify-content-center align-items-center p-1 px-2 m-1" 
                                            role="button"
                                            href={link.node.url} 
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                launch
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Apri
                                            </span>
                                        </a>

                                        <button 
                                            className="btn btn-info d-flex justify-content-center align-items-center p-1 px-2 m-1"
                                            onClick={() => {
                                                if (typeof window !== 'undefined')
                                                    navigator.clipboard.writeText(link.node.url)
                                                        .then(() => alert('Url Copiato'))
                                            }}
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                content_copy
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Copia Link
                                            </span>
                                        </button>

                                        <Link 
                                            className="btn btn-secondary d-flex justify-content-center align-items-center p-1 px-2 m-1" 
                                            role="button" 
                                            to="/scheda-link"
                                            state={{id: link.node.id, mode: EDIT_MODE}}
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                edit
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Modifica
                                            </span>
                                        </Link>

                                        <button 
                                            className="btn btn-danger d-flex justify-content-center align-items-center p-1 px-2 m-1" 
                                            onClick={() => cancella({variables: {input: { id: link.node.id }}})}
                                        >
                                            <span className="material-icons md-18 mx-1">
                                                delete
                                            </span>
                                            <span className="d-none d-lg-inline">
                                                Cancella
                                            </span>
                                        </button>

                                    </div>
                                </td>
                            </tr>
                        ))}  
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export { TabellaArchivio, TabellaClienti, TabellaProgetti, TabellaDocumenti, TabellaLink }
