import React from "react"
import { Cliente, useCreateCliente, schemaCliente, useCliente, useUpdateCliente } from '../services/cliente'
import { Progetto, schemaProgetto, useCreateProgetto, useProgetto, useUpdateProgetto } from "../services/progetto"
import { Link, schemaLink, useCreateLink, useLink, useUpdateLink } from "../services/link"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { LayoutScheda } from './layout'
import { ToolbarSchedaCliente, ToolbarSchedaProgetto, ToolbarSchedaLink, Breadcrumb } from "./toolbar"
import { ADD_MODE, EDIT_MODE, VIEW_MODE } from "./form"

// SCHEDE COMPONENTS

const Scheda = ({children}) => (
    <div className="row d-flex justify-content-center my-5 mx-0">
        <div className="col-lg-9">
            <div className="shadow w-100 bg-light border border-muted rounded">
                <div className="d-flex align-items-center w-100 bg-gray p-3 border-bottom rounded bg-ghostwhite">
                    <span className="material-icons md-24 mr-2 text-primary">
                        book
                    </span>
                    <p className="h3 my-1">Nome</p>
                </div>
                <div className="w-100 px-2 py-3">{children}</div>
            </div>
        </div>
    </div>
)

const AddSchedaCliente = () => {
    let initialValues = new Cliente()
    const [createCliente, { loading, error }] = useCreateCliente()
    const onSubmit = (values) =>
        createCliente({ variables: { input: values } })
            .then(() => navigate('/clienti'))
    const Button = <button type="submit">Crea Cliente</button>
    const Toolbar = <ToolbarSchedaCliente mode={ADD_MODE}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-cliente', titolo: 'Scheda Cliente'}]}/>

    if (loading) return "Submitting..."
    if (error) return `Submission error! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => onSubmit(values)}
                    validationSchema={schemaCliente}
                >
                    <FormCliente
                        Button={Button}
                        disableInput={false}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const EditSchedaCliente = ({id}) => {
    const { data, loading, error } = useCliente(id)
    const [updateCliente] = useUpdateCliente()
    const onSubmit = (values) =>
        updateCliente({ variables: { input: values } })
            .then(() => navigate('/scheda-cliente', {state: {id: id, mode: VIEW_MODE}}))
    const Button = <button type="submit">Modifica Cliente</button>
    const Toolbar = <ToolbarSchedaCliente mode={EDIT_MODE} id={id}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-cliente', titolo: 'Scheda Cliente'}]}/>

    if (loading) return "Submitting..."
    if (error) return `Submission error! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={{id, ...Cliente.importFromObject(data.cliente)}}
                    onSubmit={(values) => onSubmit(values)}
                    validationSchema={schemaCliente}
                >
                    <FormCliente
                        Button={Button}
                        disableInput={false}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const ViewSchedaCliente = ({id}) => {
    const { data, loading, error } = useCliente(id)
    const Toolbar = <ToolbarSchedaCliente mode={VIEW_MODE} id={id}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-cliente', titolo: 'Scheda Cliente'}]}/>

    if (loading) return "Caricamento..."
    if (error) return `Errore! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={{id, ...Cliente.importFromObject(data.cliente)}}
                >
                    <FormCliente
                        disableInput={true}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const FormCliente = ({Button = null, disableInput = false}) => (
    <Form>
        <fieldset id="legale-cliente">
            <legend className="text-secondary">
                Informazioni Legali
            </legend>
            <div className="row form-group">
                <div className="col-12 col-lg-8">
                    <label className="form-label">Ragione Sociale</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="ragioneSociale"
                        />
                        <ErrorMessage name="ragioneSociale"/>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <label className="form-label">
                        Tipologia Società
                    </label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="tipologiaCliente"
                            placeholder="Srl, SpA, Libero prof..."
                        />
                        <ErrorMessage name="tipologiaCliente"/>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="form-label">REA</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="nRea"
                            placeholder="AA0000"
                        />
                        <ErrorMessage name="nRea"/>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="form-label">VAT Number</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="vatNumber"
                            placeholder="AA0000"
                        />
                        <ErrorMessage name="vatNumber"/>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="form-label">Partita IVA</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="pIva"
                            placeholder="11111111111"
                        />
                        <ErrorMessage name="pIva"/>
                    </div>
                </div>
                <div className="col-12 col-sm-9 col-lg-6">
                    <label className="form-label">Codice Fiscale</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="cFiscale"
                        />
                        <ErrorMessage name="cFiscale"/>
                    </div>
                </div>
                <div className="col-12 col-lg-12">
                    <label className="form-label">Sede Legale</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="sedeLegale"
                            placeholder="Via ..."
                        />
                        <ErrorMessage name="sedeLegale"/>
                        {/* <Field
                            disabled={disableInput}
                            className="form-control my-1 w-75"
                            type="text"
                            name="sedeLegaleVia"
                            placeholder="Via ..."
                        />
                        <p className="my-1"> , </p>
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-25"
                            type="text"
                            name="sedeLegaleCivico"
                            placeholder="Civico"
                            minLength="1"
                            maxLength="10"
                        /> */}
                    </div>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="capSedeLegale"
                            placeholder="CAP"
                        />
                        <ErrorMessage name="capSedeLegale"/>
                        {/* <p className="my-1"> - </p>
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-50"
                            type="text"
                            name="citta"
                            placeholder="Citta"
                            minLength="1"
                            maxLength="10"
                        /> */}
                    </div>
                    <div className="d-flex d-sm-flex justify-content-between align-items-center align-items-sm-center">
                        {/* <Field
                            disabled={disableInput}
                            className="form-control my-1 mr-1 w-25"
                            type="text"
                            name="sedeLegale-via"
                            placeholder="Prov"
                        />
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 ml-1 w-75"
                            type="text"
                            name="sedeLegale-via"
                            placeholder="Nazione"
                            minLength="1"
                            maxLength="10"
                        /> */}
                    </div>
                </div>
                <div className="col-12 col-lg-9 col-xl-9">
                    <label className="form-label">PEC</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="pec"
                            placeholder="pec@example.com"
                        />
                        <ErrorMessage name="pec"/>
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset id="persone-cliente">
            <legend className="text-secondary">Responsabili</legend>
            <div className="row form-group">
                <div className="col-12">
                    <label className="form-label">
                        Nominativo Rappresentante Legale
                    </label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 mr-1 w-50"
                            type="text"
                            placeholder="Nome"
                            name="nomeResponsabileLegale"
                        />
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 ml-1 w-50"
                            type="text"
                            placeholder="Cognome"
                            name="cognomeResponsabileLegale"
                        />
                    </div>
                </div>
                <div className="col-12">
                    <label className="form-label">
                        Nominativo Responsabile Trattamento Dati
                    </label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 mr-1 w-50"
                            type="text"
                            name="nomeRtd"
                            placeholder="Nome"
                        />
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 ml-1 w-50"
                            type="text"
                            placeholder="Cognome"
                            name="cognomeRtd"
                        />
                    </div>
                </div>
                <div className="col-12">
                    <label className="form-label">
                        Nominativo Titolare Trattamento Dati
                    </label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 mr-1 w-50"
                            type="text"
                            name="nomeTtd"
                        />
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 ml-1 w-50"
                            type="text"
                            name="cognomeTtd"
                        />
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset id="recapiti-cliente">
            <legend className="text-secondary">Recapiti</legend>
            <div className="row form-group">
                <div className="col-12">
                    <label className="form-label">Indirizzo Mail</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="mail"
                        />
                        <ErrorMessage name="mail"/>
                    </div>
                </div>
                <div className="col-12">
                    <label className="form-label">Telefono</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="tel"
                        />
                        <ErrorMessage name="tel"/>
                    </div>
                </div>
                <div className="col-12">
                    <label className="form-label">Cellulare</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="cellulare"
                        />
                        <ErrorMessage name="cellulare"/>
                    </div>
                </div>
                <div className="col-12">
                    <label className="form-label">Sede Legale</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="sedeCliente"
                            placeholder="Via ..."
                        />
                        <ErrorMessage name="sedeCliente"/>
                    </div>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-100"
                            type="text"
                            name="capSedeCliente"
                            placeholder="CAP"
                        />
                        <ErrorMessage name="capSedeCliente"/>
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset id="dettagli-cliente">
            <legend className="text-secondary">Dettagli Cliente</legend>
            <div className="row form-group">
                <div className="col-12 col-md-6">
                    <label className="form-label">ID Cliente</label>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="id"
                            defaultValue="/api/clientes/0"
                            disabled
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <label className="form-label">Valore Cliente</label>
                    <div className="d-flex align-items-center">
                        <span className="material-icons mr-2">
                            euro
                        </span>
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="valoreCliente"
                        />
                        <ErrorMessage name="valoreCliente"/>
                    </div>
                </div>
                {/* <div className="col-12 d-none">
                    <label className="form-label">
                        Metodi di Pagamento
                    </label>
                    <div className="d-flex align-items-center">
                        <select className="form-select my-2 w-100">
                            <optgroup label="This is a group">
                                <option value="12" defaultValue>
                                    This is item 1
                                </option>
                                <option value="13">
                                    This is item 2
                                </option>
                                <option value="14">
                                    This is item 3
                                </option>
                            </optgroup>
                        </select>
                    </div>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="..."
                            placeholder="1234 - 2346 - 5678 - 9876"
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-25"
                            type="text"
                            name="..."
                            placeholder="12"
                        />
                        <p className="my-1"> / </p>
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-25"
                            type="text"
                            name="..."
                            placeholder="24"
                        />
                        <p className="my-1"> - </p>
                        <Field
                            disabled={disableInput}
                            className="form-control my-1 w-25"
                            type="text"
                            name="cvv"
                            placeholder="CVV"
                        />
                    </div>
                </div> */}
                <div className="col-12">
                    <label className="form-label">Note</label>
                    <div className="d-flex align-items-center"></div>
                    <div className="d-flex align-items-center">
                        <Field
                            disabled={disableInput}
                            className="form-control my-1"
                            type="text"
                            name="noteCliente"
                        />
                        <ErrorMessage name="noteCliente"/>
                    </div>
                </div>
            </div>
        </fieldset>
        {Button}
    </Form>
)

const AddSchedaProgetto = () => {
    let initialValues = new Progetto()
    const [createProgetto, { loading, error }] = useCreateProgetto()
    const onSubmit = (values) =>
        createProgetto({ variables: { input: values } })
            .then(() => navigate('/progetti'))
    const Button = <button type="submit">Crea Progetto</button>
    const Toolbar = <ToolbarSchedaProgetto mode={ADD_MODE}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-progetto', titolo: 'Scheda Progetto'}]}/>

    if (loading) return "Submitting..."
    if (error) return `Submission error! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => onSubmit(values)}
                    validationSchema={schemaProgetto}
                >
                    <FormProgetto
                        Button={Button}
                        disableInput={false}
                    />
                </Formik>
            </Scheda>
        }
        Toolbar={Toolbar}
    />
}

const EditSchedaProgetto = ({id}) => {
    const { data, loading, error } = useProgetto(id)
    const [updateProgetto] = useUpdateProgetto()
    const onSubmit = (values) =>
        updateProgetto({ variables: { input: values } })
            .then(() => navigate('/scheda-progetto', {state: {id: id, mode: VIEW_MODE}}))
    const Button = <button type="submit">Modifica Progetto</button>
    const Toolbar = <ToolbarSchedaProgetto mode={EDIT_MODE} id={id}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-progetto', titolo: 'Scheda Progetto'}]}/>

    if (loading) return "Submitting..."
    if (error) return `Submission error! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={{id, ...Progetto.importFromObject(data.progetto)}}
                    onSubmit={(values) => onSubmit(values)}
                    validationSchema={schemaProgetto}
                >
                    <FormProgetto
                        Button={Button}
                        disableInput={false}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const ViewSchedaProgetto = ({id}) => {
    const { data, loading, error } = useProgetto(id)
    const Toolbar = <ToolbarSchedaProgetto mode={VIEW_MODE} id={id}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-progetto', titolo: 'Scheda Progetto'}]}/>

    if (loading) return "Caricamento..."
    if (error) return `Errore! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={{id, ...Progetto.importFromObject(data.progetto)}}
                >
                    <FormProgetto
                        disableInput={true}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const FormProgetto = ({Button = null, disableInput = false}) => {
    return (
        <Form>
            <fieldset id="info-primarie">
                <legend className="text-secondary">
                    Informazioni Primarie
                </legend>
                <div className="row form-group">
                    <div className="col-12 col-lg-8">
                        <label className="form-label">Nome Progetto</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="nome"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <label className="form-label">TAG Progetto</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="tag"
                                placeholder="tag"
                            />
                        </div>
                    </div>
                    <div className="col-6 col-lg-4">
                        <label className="form-label">
                            Data avvio lavori
                        </label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control"
                                type="date"
                                name="dataAvvio"
                            />
                        </div>
                    </div>
                    <div className="col-6 col-lg-4">
                        <label className="form-label">
                            Data fine lavori
                        </label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control"
                                type="date"
                                name="dataFine"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <label className="form-label">Durata lavori</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="durataProgetto"
                                placeholder="Durata"
                            />
                        </div>
                    </div>
                    {/* <div className="col-12 col-sm-9 col-lg-12">
                        <label className="form-label">
                            Affiliazione Progetto
                        </label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="CF"
                                minLength="8"
                                maxLength="16"
                            />
                        </div>
                    </div> */}
                </div>
            </fieldset>
            <fieldset id="info-risorse">
                <legend className="text-secondary">
                    Informazioni Risorse
                </legend>
                <div className="row form-group">
                    <div className="col-12 col-lg-9">
                        <label className="form-label">
                            Risorse Umane necessarie al progetto
                        </label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="risorseUmaneDm"
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-4">
                        <label className="form-label">
                            Uscite progetto
                        </label>
                        <div className="d-flex align-items-center">
                            <span className="material-icons mr-2">
                                euro
                            </span>
                            <Field
                                disabled={disableInput}
                                className="form-control"
                                type="number"
                                name="usciteSpeseDm"
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-4">
                        <label className="form-label">
                            Entrate progetto
                        </label>
                        <div className="d-flex align-items-center">
                            <span className="material-icons mr-2">
                                euro
                            </span>
                            <Field
                                disabled={disableInput}
                                className="form-control"
                                type="number"
                                name="entrateSpeseDm"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-4">
                        <label className="form-label">
                            Guadagno progetto
                        </label>
                        <div className="d-flex align-items-center">
                            <span className="material-icons mr-2">
                                euro
                            </span>
                            <Field
                                disabled={disableInput}
                                className="form-control"
                                type="number"
                                name="guadagnoDm"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset id="dettagli-progetto">
                <legend className="text-secondary">
                    Dettagli Progetto
                </legend>
                <div className="row form-group">
                    <div className="col-4 col-md-6">
                        <label className="form-label">ID Progetto</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={true}
                                className="form-control my-1"
                                type="text"
                                name="id"
                                defaultValue="/api/progettos/0"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-8 col-md-6">
                        <label className="form-label">
                            Data creazione progetto
                        </label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control"
                                type="date"
                                name="dataCreazioneProgetto"
                            />
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <label className="form-label">Note</label>
                        <div className="d-flex align-items-center"></div>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="noteCliente"
                                maxLength="255"
                            />
                        </div>
                    </div> */}
                </div>
            </fieldset>
            {Button}
        </Form>
    )
}

const AddSchedaLink = () => {
    let initialValues = new Link()
    const [createLink, { loading, error }] = useCreateLink()
    const onSubmit = (values) =>
        createLink({ variables: { input: values } })
            .then(() => navigate('/link'))
    const Button = <button type="submit">Crea Link</button>
    const Toolbar = <ToolbarSchedaLink mode={ADD_MODE}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-link', titolo: 'Scheda Link'}]}/>

    if (loading) return "Submitting..."
    if (error) return `Submission error! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => onSubmit(values)}
                    validationSchema={schemaLink}
                >
                    <FormLink
                        Button={Button}
                        disableInput={false}
                    />
                </Formik>
            </Scheda>
        }
        Toolbar={Toolbar}
    />
}

const EditSchedaLink = ({id}) => {
    const { data, loading, error } = useLink(id)
    const [updateLink] = useUpdateLink()
    const onSubmit = (values) =>
        updateLink({ variables: { input: values } })
            .then(() => navigate('/scheda-link', {state: {id: id, mode: VIEW_MODE}}))
    const Button = <button type="submit">Modifica Link</button>
    const Toolbar = <ToolbarSchedaLink mode={EDIT_MODE} id={id}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-link', titolo: 'Scheda Link'}]}/>

    if (loading) return "Submitting..."
    if (error) return `Submission error! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={{id, ...Link.importFromObject(data.link)}}
                    onSubmit={(values) => onSubmit(values)}
                    validationSchema={schemaLink}
                >
                    <FormLink
                        Button={Button}
                        disableInput={false}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const ViewSchedaLink = ({id}) => {
    const { data, loading, error } = useLink(id)
    const Toolbar = <ToolbarSchedaLink mode={VIEW_MODE} id={id}/>
    const BreadcrumbScheda = <Breadcrumb arrayLink={[{uri: '/scheda-link', titolo: 'Scheda Link'}]}/>

    if (loading) return "Caricamento..."
    if (error) return `Errore! ${error.message}`

    return <LayoutScheda
        Breadcrumb={BreadcrumbScheda}
        Scheda={
            <Scheda>
                <Formik
                    initialValues={{id, ...Link.importFromObject(data.link)}}
                >
                    <FormLink
                        disableInput={true}
                    />
                </Formik>
            </Scheda>
        } 
        Toolbar={Toolbar}
    />
}

const FormLink = ({Button = null, disableInput = false}) => {
    return (
        <Form>
            <fieldset id="info-primarie">
                <legend className="text-secondary">
                    Informazioni Primarie
                </legend>
                <div className="row form-group">
                    <div className="col-12 col-lg-8">
                        <label className="form-label">Nome Link</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="nome"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <label className="form-label">Url</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="url"
                                placeholder="https://www.example.com"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <label className="form-label">TAG Link</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={disableInput}
                                className="form-control my-1"
                                type="text"
                                name="tag"
                                placeholder="tag"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset id="dettagli-link">
                <legend className="text-secondary">
                    Dettagli Link
                </legend>
                <div className="row form-group">
                    <div className="col-4 col-md-6">
                        <label className="form-label">ID Link</label>
                        <div className="d-flex align-items-center">
                            <Field
                                disabled={true}
                                className="form-control my-1"
                                type="text"
                                name="id"
                                defaultValue="/api/links/0"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            {Button}
        </Form>
    )
}

Scheda.propType = { children: PropTypes.node.isRequired }

export {
    AddSchedaCliente,
    EditSchedaCliente,
    ViewSchedaCliente,
    AddSchedaProgetto,
    EditSchedaProgetto,
    ViewSchedaProgetto,
    AddSchedaLink,
    EditSchedaLink,
    ViewSchedaLink
}
