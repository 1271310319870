import * as React from "react"
import dmLogo from "../images/dm-logo.svg"
import { logout } from "../services/auth"
import { navigate, Link } from "gatsby"

const SimplyFooter = () => (
    <footer
        className="bg-dark d-flex justify-content-start align-items-center px-4 py-2 mb-0 fix-bottom"
        id="dm-foo"
    >
        <div>
            <img className="mr-2 img-logo" src={dmLogo} alt="logo" />
            <Link className="navbar-brand" href="/">
                DIGITAL MOTUS
            </Link>
        </div>
    </footer>
)

const Footer = () => (
    <footer
        className="bg-dark d-flex justify-content-between align-items-center px-4 py-2 mb-0 fix-bottom"
        id="dm-foo"
    >
        <div>
            <img className="mr-2 img-logo" src={dmLogo} alt="logo"/>
            <a className="navbar-brand" href="#">
                DIGITAL MOTUS
            </a>
        </div>
        <div>
            <button
                className="btn btn-danger d-flex justify-content-center align-items-center p-2"
                type="button"
                onClick={() => logout(() => navigate("/login"))}
            >
                <span className="material-icons">power_settings_new</span>
            </button>
        </div>
    </footer>
)

export { SimplyFooter, Footer }
