/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import { Navbar, SimplyNavbar } from "./navbar";
import { Footer, SimplyFooter } from "./footer";

import './material.css'
import './custom-styles.css'
import './talcon.css'

import { ToolbarDashboard, ToolbarArchivio, ToolbarClienti, ToolbarDocumenti, Breadcrumb, FormLogin,  } from "./toolbar";
import { SectionNameDashboard, SectionNameArchivio, SectionNameClienti, SectionNameProgetti } from "./toolbar"
import { SchedaCliente, SchedaProgetto } from "./scheda"
import { TabellaArchivio, TabellaClienti, TabellaDocumenti, TabellaLink } from "./tabella"
import {isBrowser, isLoggedIn} from "../services/auth";
import {navigate} from "gatsby";

const PrivateRoute = ({ children }) => {
    if (isBrowser && !isLoggedIn()) {
        navigate("/login")
        return null
    }
    return children
}

const LayoutInterno = ({Breadcrumb, SectionName, Toolbar}) => (
    <PrivateRoute>
        <Navbar/>
        <div className="main-screen">
            <div className="w-100 mb-3">
                <Breadcrumb/>
                <div className="d-sm-flex justify-content-between align-items-sm-center w-100 px-4 my-2 second-nav-div border-bottom">
                    <SectionName/>
                    <Toolbar/>
                </div>
            </div>
        </div>
        <Footer/>
    </PrivateRoute>
)

const LayoutTabella = ({Breadcrumb, SectionName, Toolbar, Tabella}) => (
    <PrivateRoute>
        <Navbar/>
        <div className="main-screen">
            <div className="w-100 mb-3">
                <Breadcrumb/>
                <div className="d-sm-flex justify-content-between align-items-sm-center w-100 px-4 my-2 second-nav-div border-bottom">
                    <SectionName/>
                    <Toolbar/>
                </div>
            </div>
            <div className="w-100 mb-3">
                <div className="row d-flex justify-content-center align-items-center mx-0 my-5">
                    <div className="col-9">
                        <Tabella/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </PrivateRoute>
)

const LayoutScheda = ({Breadcrumb, Scheda, Toolbar}) => (
  <PrivateRoute>
    <Navbar/>
      <div className="main-screen">
          <div className="w-100 mb-3">
            {Breadcrumb}
            {Toolbar}
          </div>
          <div className="w-100 my-4">
            {Scheda}
          </div>
      </div>
    <Footer/>
  </PrivateRoute>
)

const BreadcrumbDashboard = () => <Breadcrumb arrayLink={[]}/>
const BreadcrumbArchivio = () => <Breadcrumb arrayLink={[{uri: '/archivio', titolo: 'Archivio'}]}/>
const BreadcrumbDocumenti = () => <Breadcrumb arrayLink={[{uri: '/documenti', titolo: 'Documenti'}]}/>

const LayoutDashboard = () => <LayoutInterno Breadcrumb={BreadcrumbDashboard} SectionName={SectionNameDashboard} Toolbar={ToolbarDashboard}/>
const LayoutArchivio = () => <LayoutTabella Breadcrumb={BreadcrumbArchivio} SectionName={SectionNameArchivio} Toolbar={ToolbarArchivio} Tabella={TabellaArchivio}/>
const LayoutDocumenti = () => <LayoutTabella Breadcrumb={BreadcrumbDocumenti} SectionName={SectionNameProgetti} Toolbar={ToolbarDocumenti} Tabella={TabellaDocumenti}/>

const LayoutLogin = () => (
  <>
    <SimplyNavbar/>
    <div className="w-100 login-page h-100 my-5">
        <div className="row d-flex justify-content-center align-items-center mx-0 my-5">
          <div className="col-9">
            <FormLogin/>
          </div>
        </div>
      </div>
    <SimplyFooter/>
  </>
)

const LayoutNotFound = ({ children }) => {
  return (
    <>
      {children}
      <div className="w-100 login-page h-100 notFound-page my-5">
          <div className="row d-flex justify-content-center align-items-center mx-0 my-5">
            <div className="col-9 w-100">
                <div className="w-100 d-flex justify-content-center align-items-center my-3">
                  <h1 className="text-primary">Error 404: Not Found</h1>
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center my-2">
                  <p className="text-light">Pagina non trovata. Torna alla <a href="/">Dashboard</a></p>
                </div>
            </div>
          </div>
        </div>
      <SimplyFooter/>
    </>
  )
}

const LayoutLink = () => (
  <>
    <Navbar/>
    <div className="main-screen">
      <div className="w-100 mb-3">
        <div className="row d-flex justify-content-center align-items-center mx-0 my-5">
          <div className="col-9">
              <TabellaLink/>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
)

LayoutInterno.propTypes = { children: PropTypes.node.isRequired }
PrivateRoute.propTypes = { children: PropTypes.node.isRequired }

export {
    LayoutDashboard,
    LayoutArchivio,
    LayoutDocumenti,
    LayoutScheda,
    LayoutTabella,
    LayoutLogin,
    LayoutNotFound,
    LayoutLink
}
