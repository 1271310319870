import React from "react"

const ADD_MODE = 'ADD'
const EDIT_MODE = 'EDIT'
const VIEW_MODE = 'VIEW'

const isAddMode  = mode => mode === ADD_MODE
const isEditMode = mode => mode === EDIT_MODE
const isViewMode = mode => mode === VIEW_MODE

/**
 * Define Your Form, then use the returned function to render a form based on choosen mode.
 * @param {React.Component} AddModeForm 
 * @param {React.Component} EditModeForm 
 * @param {React.Component} ViewModeForm 
 * @returns {Callback}
 */
const useFormMode = (AddModeForm, EditModeForm, ViewModeForm, mode) => {
    switch (mode) {
        case ADD_MODE: return AddModeForm
        case EDIT_MODE: return EditModeForm
        case VIEW_MODE: return ViewModeForm
    }
    return null
}

export {
    ADD_MODE,
    EDIT_MODE,
    VIEW_MODE,
    isAddMode,
    isEditMode,
    isViewMode,
    useFormMode
}
