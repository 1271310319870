import { gql, useMutation, useQuery } from "@apollo/client"
import * as Yup from 'yup'

class Link {
    nome = ''
    url = ''
    tag = ''
    
    static importFromObject(obj) {
        const link = new Link()
        for (let attr in link)
            link[attr] = obj[attr]
        return link
    }
}

const CREATE_LINK = gql`
    mutation CreateLink($input: createLinkInput!) {
        createLink(input: $input) {
            link {
                id
            }
        }
    }
`;

const UPDATE_LINK = gql`
    mutation UpdateLink($input: updateLinkInput!) {
        updateLink(input: $input) {
            clientMutationId
        }
    }
`;

const DELETE_LINK = gql`
    mutation DeleteLink($input: deleteLinkInput!) {
        deleteLink(input: $input) {
            clientMutationId
        }
    }
`;

const GET_LINKS = gql`
    query GetLinks {
        links {
            edges {
                node {
                    id
                    nome
                    url
                }
            }
        }
    }
`;

const GET_LINK = gql`
    query GetLink($id: ID!) {
        link(id: $id) {
            id
            nome
            url
            tag
        }
    }
`;

const schemaLink = Yup.object().shape({
    nome: Yup.string(),
    url: Yup.string(),
    tag: Yup.string(),
})

const useCreateLink = () =>
    useMutation(CREATE_LINK)

const useUpdateLink = () =>
    useMutation(UPDATE_LINK)

const useDeleteLink = (id = null) =>
    useMutation(DELETE_LINK, {variables: {input: {id: id}}})

const useLinks = () =>
    useQuery(GET_LINKS,{pollInterval: 5000})

const useLink = (id) =>
    useQuery(GET_LINK, {variables: {id: id}})

export {
    Link,
    useCreateLink,
    useUpdateLink,
    useDeleteLink,
    useLinks,
    useLink,
    schemaLink
}