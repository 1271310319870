import { gql, useMutation, useQuery } from "@apollo/client"
import * as Yup from 'yup'

class Cliente {
    ragioneSociale = ''
    tipologiaCliente = ''
    nRea = ''
    vatNumber = ''
    pIva = ''
    cFiscale = ''
    sedeLegale = ''
    capSedeLegale = ''
    pec = ''
    nomeResponsabileLegale = ''
    cognomeResponsabileLegale = ''
    nomeRtd = ''
    cognomeRtd = ''
    nomeTtd = ''
    cognomeTtd = ''
    mail = ''
    tel = ''
    cellulare = ''
    sedeCliente = ''
    capSedeCliente = ''
    noteCliente = ''
    valoreCliente = ''
    status = ''

    static importFromObject(obj) {
        const cliente = new Cliente()
        for (let attr in cliente)
            cliente[attr] = obj[attr]
        return cliente
    }
}

const CREATE_CLIENTE = gql`
    mutation CreateCliente($input: createClienteInput!) {
        createCliente(input: $input) {
            cliente {
                id
            }
        }
    }
`;

const UPDATE_CLIENTE = gql`
    mutation UpdateCliente($input: updateClienteInput!) {
        updateCliente(input: $input) {
            clientMutationId
        }
    }
`;

const DELETE_CLIENTE = gql`
    mutation DeleteCliente($input: deleteClienteInput!) {
        deleteCliente(input: $input) {
            clientMutationId
        }
    }
`;

const GET_CLIENTES = gql`
    query GetClientes {
        clientes {
            edges {
                node {
                    id
                    status
                    ragioneSociale
                }
            }
        }
    }
`;

const GET_CLIENTE = gql`
    query GetCliente($id: ID!) {
        cliente(id: $id) {
            capSedeCliente
            capSedeLegale
            cellulare
            cFiscale
            cognomeResponsabileLegale
            cognomeRtd
            cognomeTtd
            id
            mail
            nomeResponsabileLegale
            nomeRtd
            nomeTtd
            noteCliente
            nRea
            pec
            pIva
            ragioneSociale
            sedeCliente
            sedeLegale
            status
            tel
            tipologiaCliente
            valoreCliente
            vatNumber
        }
    }
`;

const schemaCliente = Yup.object().shape({
    ragioneSociale: Yup.string(),//.required('Inserisci il nome della società'),
    tipologiaCliente: Yup.string(),//.required(),
    nRea: Yup.string(),//.matches(/([A-Z]{2}[0-9]{4})/).required('Rea richiesta'),
    vatNumber: Yup.string(),//.matches(/([A-Z]{2}[0-9]{4})/).required('VAT Richiesto').length(10, 'Massima Lunghezza 10'),
    pIva: Yup.string(),//.required(),
    cFiscale: Yup.string(),//.required().length(11, 'Lunghezza Massima 11'),
    sedeLegale: Yup.string(),
    capSedeLegale: Yup.string(),//.length(5,'Lunghezza Massima 5'),
    pec: Yup.string(),//.email('Pec non valida'),
    nomeResponsabileLegale: Yup.string(),
    cognomeResponsabileLegale: Yup.string(),
    nomeRtd: Yup.string(),
    cognomeRtd: Yup.string(),
    nomeTtd: Yup.string(),
    cognomeTtd: Yup.string(),
    mail: Yup.string(),//.email('Email non valida'),
    tel: Yup.string(),
    cellulare: Yup.string(),
    sedeCliente: Yup.string(),
    capSedeCliente: Yup.string(),
    noteCliente: Yup.string(),
    valoreCliente: Yup.string(),
    status: Yup.string()
})

const useCreateCliente = () =>
    useMutation(CREATE_CLIENTE)

const useUpdateCliente = () =>
    useMutation(UPDATE_CLIENTE)

const useDeleteCliente = (id = null) =>
    useMutation(DELETE_CLIENTE, {variables: {input: {id: id}}})

const useClientes = () =>
    useQuery(GET_CLIENTES,{pollInterval: 500})

const useCliente = (id) =>
    useQuery(GET_CLIENTE, {variables: {id: id}})

export {
    Cliente,
    useCreateCliente,
    useUpdateCliente,
    useDeleteCliente,
    useClientes,
    useCliente,
    schemaCliente
}