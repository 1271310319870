import React from "react"
import dmLogo from "../images/dm-logo.svg"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import PropTypes from 'prop-types'
import { ADD_MODE, EDIT_MODE, VIEW_MODE, useFormMode } from './form'
import { useDeleteCliente } from "../services/cliente"
import { useDeleteProgetto } from "../services/progetto"
import { useDeleteLink } from "../services/link"

// TOOLBAR COMPONENTS

const ToolbarDashboard = () => (
    <div className="d-flex align-items-center second-nav" id="tool-dashboard">
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
            onClick={() => {
                navigate(
                    "/scheda-cliente", 
                    { state: { mode: ADD_MODE } }
                )
            }}
        >
            <span className="material-icons mx-1">add person</span>
            <span className="d-none d-md-inline">Aggiungi Cliente</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
            onClick={() => {
                navigate("/scheda-progetto")
            }}
        >
            <span className="material-icons mx-1">add book</span>
            <span className="d-none d-md-inline">Aggiungi Progetto</span>
        </button>
        <div className="form-group my-2">
            <div className="custom-control custom-switch mx-2">
                <input
                    type="checkbox"
                    id="viewToggle"
                    className="custom-control-input"
                />
                <label
                    className="form-label custom-control-label text-light"
                    htmlFor="viewToggle"
                >
                    Vista Archivio ad Icone
                </label>
            </div>
        </div>
    </div>
)

const ToolbarArchivio = ({gridMode = false}) => (
    <div className="d-flex align-items-center second-nav" id="tool-archivio">
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">add</span>
            <span className="d-none d-md-inline">Aggiungi</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">download</span>
            <span className="d-none d-md-inline">Download</span>
        </button>
        <div className="form-group my-2">
            <div className="custom-control custom-switch mx-2">
                <input
                    type="checkbox"
                    id="viewToggle"
                    className="custom-control-input"
                    checked={gridMode}
                />
                <label
                    className="form-label custom-control-label text-light"
                    htmlFor="viewToggle"
                >
                    Vista Archivio ad Icone
                </label>
            </div>
        </div>
    </div>
)

const ToolbarLink = ({gridMode = false}) => (
    <div className="d-flex align-items-center second-nav" id="tool-archivio">
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
            onClick={() => {
                navigate("/scheda-link")
            }}
        >
            <span className="material-icons mx-1">add</span>
            <span className="d-none d-md-inline">Aggiungi</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">download</span>
            <span className="d-none d-md-inline">Download</span>
        </button>
        <div className="form-group my-2">
            <div className="custom-control custom-switch mx-2">
                <input
                    type="checkbox"
                    id="viewToggle"
                    className="custom-control-input"
                    checked={gridMode}
                />
                <label
                    className="form-label custom-control-label text-light"
                    htmlFor="viewToggle"
                >
                    Vista Link ad Icone
                </label>
            </div>
        </div>
    </div>
)

const ToolbarClienti = ({gridMode = false}) => (
    <div className="d-flex align-items-center second-nav" id="tool-clienti">
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
            onClick={() => {
                navigate("/scheda-cliente")
            }}
        >
            <span className="material-icons mx-1">add</span>
            <span className="d-none d-md-inline">Aggiungi</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">download</span>
            <span className="d-none d-md-inline">Download</span>
        </button>
        <div className="form-group my-2">
            <div className="custom-control custom-switch mx-2">
                <input
                    type="checkbox"
                    id="viewToggle"
                    className="custom-control-input"
                    checked={gridMode}
                />
                <label
                    className="form-label custom-control-label text-light"
                    htmlFor="viewToggle"
                >
                    Vista Archivio ad Icone
                </label>
            </div>
        </div>
    </div>
)

const ToolbarProgetti = ({idCliente = null}) => (
    <div className="d-flex align-items-center second-nav" id="tool-progetti">
        { idCliente && <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
            onClick={() => navigate('/scheda-cliente',{state: {id: idCliente, mode: VIEW_MODE}})}
        >
            <span className="material-icons mx-1">info</span>
            <span className="d-none d-md-inline">Scheda Cliente</span>
        </button>}
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
            onClick={() => {
                navigate("/scheda-progetto")
            }}
        >
            <span className="material-icons mx-1">add</span>
            <span className="d-none d-md-inline">Aggiungi</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">download</span>
            <span className="d-none d-md-inline">Download</span>
        </button>
        <div className="form-group my-2">
            <div className="custom-control custom-switch mx-2">
                <input
                    type="checkbox"
                    id="viewToggle"
                    className="custom-control-input"
                />
                <label
                    className="form-label custom-control-label text-light"
                    htmlFor="viewToggle"
                >
                    Vista Archivio ad Icone
                </label>
            </div>
        </div>
    </div>
)

const ToolbarDocumenti = () => (
    <div className="d-flex align-items-center second-nav" id="tool-documenti">
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">info</span>
            <span className="d-none d-md-inline">Scheda Progetto</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">add</span>
            <span className="d-none d-md-inline">Aggiungi</span>
        </button>
        <button
            className="btn btn-primary d-flex align-items-center mx-1"
            type="button"
        >
            <span className="material-icons mx-1">download</span>
            <span className="d-none d-md-inline">Download</span>
        </button>
        <div className="form-group my-2">
            <div className="custom-control custom-switch mx-2">
                <input
                    type="checkbox"
                    id="viewToggle"
                    className="custom-control-input"
                />
                <label
                    className="form-label custom-control-label text-light"
                    htmlFor="viewToggle"
                >
                    Vista Archivio ad Icone
                </label>
            </div>
        </div>
    </div>
)

const ToolbarSchedaCliente = ({mode, id = null}) => {
    const [deleteCliente] = useDeleteCliente(id)
    const modifica = () => navigate(
        '/scheda-cliente', 
        {state: {id: id, mode: EDIT_MODE}}
    )
    const cancella = () => 
        deleteCliente().then(() => navigate('/clienti'))
    
    return useFormMode(
        <ToolbarScheda/>,
        <ToolbarScheda cancella={cancella}/>,
        <ToolbarScheda modifica={modifica} cancella={cancella}/>,
        mode
    )
}

const ToolbarSchedaProgetto = ({mode, id = null}) => {
    const [deleteProgetto] = useDeleteProgetto(id)
    const modifica = () => navigate(
        '/scheda-progetto', 
        {state: {id: id, mode: EDIT_MODE}}
    )
    const cancella = () => 
        deleteProgetto().then(() => navigate('/progetti'))
    
    return useFormMode(
        <ToolbarScheda/>,
        <ToolbarScheda cancella={cancella}/>,
        <ToolbarScheda modifica={modifica} cancella={cancella}/>,
        mode
    )
}

const ToolbarSchedaLink = ({mode, id = null}) => {
    const [deleteLink] = useDeleteLink(id)
    const modifica = () => navigate(
        '/scheda-link', 
        {state: {id: id, mode: EDIT_MODE}}
    )
    const cancella = () => 
        deleteLink().then(() => navigate('/link'))
    
    return useFormMode(
        <ToolbarScheda/>,
        <ToolbarScheda cancella={cancella}/>,
        <ToolbarScheda modifica={modifica} cancella={cancella}/>,
        mode
    )
}

/**
 * 
 * @param {MutationFunction} cancella 
 * @param {Callback} modificaLink 
 * @param {string} id 
 * @returns 
 */
const ToolbarScheda = ({modifica = null, cancella = null}) => (
    <div className="d-flex justify-content-center w-100">
        <div className="row d-sm-flex d-md-flex justify-content-sm-center align-items-md-center mx-0 w-100">
            <div className="col-2 col-sm-2 col-md-3 col-lg-2 col-xl-2 p-2">
                <button
                    className="btn btn-info d-flex justify-content-center align-items-center btn-action w-100 py-2"
                    type="button"
                    disabled
                >
                    <span className="material-icons md-18 mx-1 decoration-none">
                        dns
                    </span>
                    <span className="d-none d-md-flex">Server Path</span>
                </button>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-2">
                <button
                    className="btn btn-info d-flex justify-content-center align-items-center btn-action w-100 py-2"
                    type="button"
                    disabled
                >
                    <span className="material-icons md-18 mx-1 decoration-none">
                        source
                    </span>
                    <span className="d-none d-md-flex">Cartelle</span>
                </button>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 p-2">
                <button
                    className="btn btn-info d-flex justify-content-center align-items-center btn-action w-100 py-2"
                    type="button"
                    disabled
                >
                    <span className="material-icons md-18 mx-1 decoration-none">
                        info
                    </span>
                    <span className="d-none d-md-flex">Status</span>
                </button>
            </div>
            { modifica &&
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 p-2">
                    <button
                        className="btn btn-primary d-flex justify-content-center align-items-center btn-action w-100 py-2"
                        type="button"
                        onClick={() => modifica()}
                    >
                        <span className="material-icons md-18 mx-1 decoration-none">
                            edit
                        </span>
                        <span className="d-none d-md-flex">Modifica</span>
                    </button>
                </div>
            }
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 p-2">
                <button
                    className="btn btn-secondary d-flex justify-content-center align-items-center btn-action w-100 py-2"
                    type="button"
                    disabled
                >
                    <span className="material-icons md-18 mx-1 decoration-none">
                        download
                    </span>
                    <span className="d-none d-md-flex">Download</span>
                </button>
            </div>
            { cancella &&
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 p-2">
                    <button
                        className="btn btn-danger d-flex justify-content-center align-items-center btn-action w-100 py-2"
                        type="button"
                        onClick={() => cancella()}
                    >
                        <span className="material-icons md-18 mx-1 decoration-none">
                            delete
                        </span>
                        <span className="d-none d-md-flex">Cancella</span>
                    </button>
                </div>
            }
        </div>
    </div>
)

// BREADCRUMB COMPONENT

const Breadcrumb = ({arrayLink = []}) => (
    <div className="w-100">
        <ol className="breadcrumb bg-dark mb-0 rounded-0">
            <li className="breadcrumb-item">
                <a href="/">
                    <span className="d-flex align-items-center">
                        <span className="material-icons mr-1 md-18 text-breadcrumb">
                            dashboard
                        </span>
                        Dashboard
                    </span>
                </a>
            </li>
            {arrayLink.map((link) => (
                <li className="breadcrumb-item">
                    <a href={link.uri}>
                        <span>
                            <span>{link.titolo}</span>
                        </span>
                    </a>
                </li>
            ))}
        </ol>
    </div>
)

// SECTION NAME COMPONENTS

const SectionNameDashboard = () => (
    <div
        id="section-name-dashboard"
        className="d-flex align-items-center second-nav"
    >
        <span className="material-icons md-36 mx-1 text-white">dashboard</span>
        <h1 className="my-1 text-white">Dashboard</h1>
    </div>
)

const SectionNameLink = () => (
    <div
        id="section-name-dashboard"
        className="d-flex align-items-center second-nav"
    >
        <span className="material-icons md-36 mx-1 text-white">link</span>
        <h1 className="my-1 text-white">Link</h1>
    </div>
)

const SectionNameArchivio = () => (
    <div
        id="section-name-archivio"
        className="d-flex align-items-center second-nav"
    >
        <span className="material-icons md-36 mx-1 text-white">source</span>
        <h1 className="my-1 text-white">Archivio</h1>
    </div>
)

const SectionNameClienti = () => (
    <div id="section-name-clienti" className="d-flex align-items-center second-nav">
        <span className="material-icons md-36 mx-1 text-white">person</span>
        <h1 className="my-1 text-white">Clienti</h1>
    </div>
)

const SectionNameProgetti = () => (
    <div
        id="section-name-progetti"
        className="d-flex align-items-center second-nav"
    >
        <span className="material-icons md-36 mx-1 text-white">book</span>
        <h1 className="my-1 text-white">Progetti</h1>
    </div>
)

// FORM COMPONENTS

class FormLogin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: ``,
            password: ``,
        }

        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }
    handleSubmit = async event => {
        event.preventDefault()
        return handleLogin(this.state)
    }
    render() {
        if (isLoggedIn()) {
            navigate(`/`)
        }
        return (
            <form
                method="post"
                onSubmit={event =>
                    this.handleSubmit(event)
                        .then(() => navigate(`/`))
                        .catch(() =>
                            alert(
                                "Errore di Autenticazione, controlla le credenziali. In casi estremi contatta i Boss"
                            )
                        )
                }
                className="form-signin"
            >
                <div className="w-100 d-flex justify-content-center align-items-center my-3">
                    <img
                        className="mb-4 logo-login"
                        src={dmLogo}
                        alt="DM-TALCON logo"
                    />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center my-2">
                    <h1 className="h3 mb-3 text-light text-center font-weight-normal">
                        LOG IN
                    </h1>
                </div>
                <div className="my-4">
                    <div className="my-2">
                        <label htmlFor="inputEmail" className="sr-only">
                            Email address
                        </label>
                        <input
                            onChange={this.handleUpdate}
                            name="mail"
                            type="email"
                            id="inputEmail"
                            className="form-control"
                            placeholder="Email address"
                            required
                        />
                    </div>
                    <div className="my-2">
                        <label htmlFor="inputPassword" className="sr-only">
                            Password
                        </label>
                        <input
                            onChange={this.handleUpdate}
                            name="password"
                            type="password"
                            id="inputPassword"
                            className="form-control"
                            placeholder="Password"
                            required
                        />
                    </div>
                    <div className="checkbox mb-3 text-light">
                        <label>
                            <input type="checkbox" value="remember-me" />{" "}
                            Remember me
                        </label>
                    </div>
                    <div className="my-3">
                        <button
                            className="btn btn-lg btn-primary btn-block"
                            type="submit"
                        >
                            Sign in
                        </button>
                        <p className="mt-5 mb-3 text-muted">
                            © Digital Motus 2022
                        </p>
                    </div>
                </div>
            </form>
        )
    }
}

ToolbarScheda.propTypes = {
    modifica: PropTypes.func,
    cancella: PropTypes.func
}

export {
    ToolbarDashboard,
    ToolbarArchivio,
    ToolbarClienti,
    ToolbarProgetti,
    ToolbarDocumenti,
}

export {
    ToolbarSchedaCliente,
    ToolbarSchedaProgetto,
    ToolbarSchedaLink
}

export {
    SectionNameDashboard,
    SectionNameArchivio,
    SectionNameClienti,
    SectionNameProgetti,
    SectionNameLink,
    ToolbarLink
}

export { Breadcrumb, ToolbarScheda, FormLogin }
