import React from "react"
import dmLogo from "../images/dm-logo.svg"
import Link from "gatsby-link"
import { Collapse } from "reactstrap"

const Navbar = () => {
    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <nav
            className="navbar navbar-dark navbar-expand-md sticky-top bg-dark d-flex align-items-center border-bottom"
            id="dm-nav"
        >
            <div className="container-fluid">
                <img className="mr-2 img-logo" src={dmLogo} />
                <a className="navbar-brand" href="https://digitalmotus.it">
                    DIGITAL MOTUS
                </a>
                <button
                    className="navbar-toggler"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Collapse navbar isOpen={isOpen}
                    className="collapse navbar-collapse d-md-flex align-items-md-center"
                    id="navcol-1"
                >
                    <ul className="navbar-nav justify-content-around w-100">
                        <li className="nav-item">
                            <Link
                                className="nav-link d-flex align-items-center btn-nav"
                                to="/"
                            >
                                <span className="material-icons mr-2">
                                    dashboard
                                </span>
                                Dashboard
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link d-flex align-items-center btn-nav"
                                to="/archivio"
                            >
                                <span className="material-icons mr-2">folder</span>
                                Archivio
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link d-flex align-items-center btn-nav"
                                to="/clienti"
                            >
                                <span className="material-icons mr-2">person</span>
                                Clienti
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link d-flex align-items-center btn-nav"
                                to="/progetti"
                            >
                                <span className="material-icons mr-2">book</span>
                                Progetti
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center btn-nav" to="/link">
                                <span className="material-icons mr-2">link</span>
                                Link
                            </Link>
                        </li>
                    </ul>
                </Collapse>
            </div>
        </nav>
    )
}

const SimplyNavbar = () => (
    <nav
        className="navbar navbar-dark navbar-expand-md sticky-top bg-dark d-flex align-items-center border-bottom"
        id="dm-nav"
    >
        <div className="container-fluid d-flex justify-content-start">
            <img className="mr-2 img-logo" src={dmLogo} />
            <a className="navbar-brand" href="#">
                DIGITAL MOTUS
            </a>
        </div>
    </nav>
)

export { Navbar, SimplyNavbar }
